.chart-controls {
    margin-top: 20px;
}

.control-button {
    display: inline-block;
    margin-left: 20px;
    color: #000000;
    font-size: 14px;
}

.controls-loading-spinner {
    position: absolute;
    display: inline-block;
    margin-left: 20px;
    margin-top: 2px;
}
