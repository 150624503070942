.chart-container {
    position: relative;
    height: 500px;
    width: 1000px;
    max-width: 96%;
    margin-top: 50px;
}

.table-container {
    position: relative;
    width: 1000px;
    max-width: 90%;
    overflow-x: scroll;
    margin-top: 50px;
}

.data-source-text {
    margin-top: 50px;
    font-size: 16px;
}
.data-source-text > span {
    color: #000000;
}
